import React from "react"

import { Layout, AboutUs } from "../components"
import Seo from "../components/seo"

const AboutUsPage = () => {
  return (
    <Layout>
      <Seo
        title="Thai restaurant in Montpelier, Vermont"
        description="The story of the Pho Thai Express restaurant. The best Thai restaurant in Montpelier, Vermont."
      />
      <main>
        <AboutUs />
      </main>
    </Layout>
  )
}

export default AboutUsPage
